/* eslint-disable */
import React, {useEffect, useReducer, useState} from "react";
import Loading from "@commons/ui/component/Loading";
import {
    doctorPictureAlt,
    glueLanguagesToBadges,
    urlToData,
    useQuery,
    useSpecialityFormatter,
    useTimeFormatter
} from "@commons/infra/helpers";
import {useNavigate} from "react-router-dom";
import {DoctorApi} from "../../../api/public/DoctorApi";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import DoctorsFilter, {ACTION__FILTERS_UPDATED} from "../../component/DoctorsFilter";
import {CommonPatientLinks} from "@commons/infra/links";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import {FormattedMessage, useIntl} from "react-intl";
import CenteredColumnLayout from "@commons/ui/layout/CenteredColumnLayout";
import {title} from "@commons/shared";
import {LatvianMetaLinksHelmet} from "@commons/infra/i18n/MetaLinksHelmet";

let breakpoint = 'md'

const DoctorListPage = ({authentication, howItWorksForDoctorsLink}) => {
    let {formatMessage} = useIntl()
    let navigate = useNavigate()
    let query = useQuery();
    let timeFormatter = useTimeFormatter();

    let speciality = (query.get("s") || "").toUpperCase()

    let [doctors, setDoctors] = useState([]);
    let [isLoading, setIsLoading] = useState(true);

    let filterReducer = (asd, action) => {
        switch (action.type) {
            case ACTION__FILTERS_UPDATED:
                if (action.speciality) {
                    navigate(`${location.pathname}?s=${action.speciality.toLowerCase()}`, {replace: true});
                } else {
                    if (asd.speciality) {
                        navigate(`${location.pathname}`, {replace: true});
                    }
                }
                return {search: action.search, speciality: action.speciality}
        }
    }
    // noinspection JSCheckFunctionSignatures
    let [filters, dispatcher] = useReducer(filterReducer, {speciality})

    useEffect(() => {
        setIsLoading(true)
        DoctorApi.getDoctors({search: filters.search, speciality: filters.speciality ? [filters.speciality] : []})
            .then(r => r.data.doctors)
            .then(d => setDoctors(d))
            .finally(() => setIsLoading(false));
    }, [filters.search, filters.speciality])

    return <>
        <CenteredColumnLayout cols={8}>

            <LatvianMetaLinksHelmet/>
            <Helmet>
                <title>{title(formatMessage({id: 'pages.doctors.page-title'}))}</title>
                <meta name="description" content={formatMessage({id: 'pages.doctors.meta.description'})}/>
            </Helmet>

            <h1 className={"page-heading"}>
                <FormattedMessage id={"pages.doctors.heading"}/>
            </h1>
            <p className={`mb-3 d-none d-${breakpoint}-block me-${breakpoint}-7 fs-lg`}>
                <FormattedMessage id={"pages.doctors.text-1"}/>
            </p>
            {!authentication.isAuthenticated && <p className={"fs-lg"}>
                <FormattedMessage id={"pages.doctors.cta-1"}
                                  values={{
                                      link: chunk => <LocalizedLink
                                          to={howItWorksForDoctorsLink}>{chunk}</LocalizedLink>
                                  }}/>
            </p>}

            <DoctorsFilter defaultSpeciality={speciality} dispatcher={dispatcher}/>

            <div className={"py-4 py-md-4"}>

                {isLoading && <div className={"text-center mt-3"}><Loading/></div>}

                {!isLoading && doctors.length === 0 && <>
                    <h3 className={"mt-4"}>
                        <FormattedMessage id={"pages.doctors.nothings-found"}/>
                    </h3>
                </>}

                {!isLoading && doctors.length > 0 && <>
                    <div className={`d-none d-${breakpoint}-flex row doctor-th border-top-0`}
                         style={{marginLeft: '92px'}}>

                        <div className={"doctor-td col-6 doctor-td"}/>

                        <div className={"col-3 doctor-td justify-content-end"}>
                            <p className="text-end">
                                <FormattedMessage id={"pages.doctor.price"} values={{br: <br/>}}/>
                            </p>
                        </div>

                        <div className={"col-3 doctor-td justify-content-end"}>
                            <p className="text-end">
                                <FormattedMessage id={"pages.doctors.nearest-appointment"}/>
                            </p>
                        </div>
                    </div>

                    {doctors.map(doctor => <Row
                        imgSrc={urlToData(doctor.thumbnail)}
                        name={doctor.fullName}
                        exLastName={doctor.exLastName}
                        speciality={doctor.specialities}
                        languages={glueLanguagesToBadges(doctor.languages)}
                        defaultAppointmentPrice={doctor.settings.appointment.price}
                        defaultAppointmentDuration={doctor.settings.appointment.duration}
                        nearestAppointmentDate={doctor.nearestAppointment.id ? <>
                            {timeFormatter.formatShortDateTime(doctor.nearestAppointment.from)}<br/>
                            {timeFormatter.formatTimeTo(doctor.nearestAppointment.from)}
                        </> : <>
                            {!doctor.settings.appointmentRequest.enabled && <>&ndash;</>}
                            {doctor.settings.appointmentRequest.enabled && <>
                                <LocalizedLink to={CommonPatientLinks.doctor.requestAppointment(doctor.slug)}>
                                    <FormattedMessage id={"pages.doctors.request-appointment"}/>
                                </LocalizedLink>

                            </>}
                        </>}
                        link={CommonPatientLinks.doctor.doctor(doctor.slug)}
                    />)}
                </>}
            </div>
        </CenteredColumnLayout>
    </>
};

let Row = ({
               imgSrc,
               name,
               exLastName,
               speciality,
               languages,
               nearestAppointmentDate,
               defaultAppointmentPrice,
               defaultAppointmentDuration,
               link
           }) => {
    let navigate = useNavigate()
    let specialityFormatter = useSpecialityFormatter();

    let goTo = (route) => {
        navigate(route)
    }

    let onClick = e => {
        if (e.target.tagName === 'A') return;
        e.preventDefault()
        goTo(link)
    }

    return <div className={"d-flex table-list-item"}>

        <LocalizedLink to={link} className={"text-decoration-none"}>
            <div className="table-list-item-thumbnail">
                <DoctorThumbnail
                    className={"me-2"}
                    imgSrc={imgSrc}
                    alt={doctorPictureAlt(name, specialityFormatter.formatSpecialities(speciality))}/>
            </div>
        </LocalizedLink>

        <div className={"flex-grow-1"}>
            <LocalizedLink to={link} className={"text-decoration-none link-normal-2"}>
                <div className="row">
                    <div className={`col-${breakpoint}-6 d-flex`}>
                        <div>
                            <div className="h5 mb-0">
                                {name}
                                {exLastName && <>&nbsp;<small className={"text-muted"}>
                                    (ex {exLastName})
                                </small></>}
                            </div>
                            <p className={""}>
                                {specialityFormatter.formatSpecialities(speciality)}<br/>
                                <p className="mb-2 mt-1 fs-sm-on-sm">{languages}</p>
                            </p>
                        </div>
                    </div>

                    <div className={`col-6 col-${breakpoint}-3 text-${breakpoint}-end`}>
                        <div className={`d-${breakpoint}-none fs-sm mt-2`}>
                            <FormattedMessage id={"pages.doctor.price"} values={{br: <br/>}}/>
                        </div>
                        <p>
                            {defaultAppointmentPrice} &euro; <span
                            className={`d-inline d-${breakpoint}-none`}> / {defaultAppointmentDuration} min</span>
                            <span className={`d-none d-${breakpoint}-block`}>{defaultAppointmentDuration} min</span>
                        </p>
                    </div>

                    <div className={`col-6 col-${breakpoint}-3 text-${breakpoint}-end`}>
                        <div className={`d-${breakpoint}-none fs-sm mt-2`}>
                            <FormattedMessage id={"pages.doctors.nearest-appointment"}/>:
                        </div>
                        <p className="mb-2 fs-sm-on-sm">{nearestAppointmentDate}</p>
                    </div>
                </div>
            </LocalizedLink>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return {
        authentication: state.authentication
    }
};

export let DoctorThumbnail = ({className = "", size = 75, alt, imgSrc}) => {
    return <div className={"rounded-circle doctor-thumbnail " + className}
                style={{width: size, height: size, backgroundImage: `url("${imgSrc}")`, aspectRatio: 1/1}}
                title={alt}/>
}

export default connect(mapStateToProps)(DoctorListPage);
