import {backendUrl, pathUrl} from "@commons/infra/helpers";
import {storyblokRoutePart} from "@commons/infra/storyblok/data";

export let PublicLinks = {
    home: () => "/",
    doctors: (speciality) => {
        if (speciality) {
            return {id: `doctors`, queryParams: {"s": speciality}}
        } else {
            return {id: `doctors`}
        }
    },
    doctor: (slug) => ({id: 'doctor', params: {doctorId: slug}}),
    about: () => ({id: `about`}),
    faq: () => ({id: `faq`}),
    micAndCameraInstructions: () => "/instructions/camera-mic-access",
    contacts: () => ({id: `contacts`}),
    howItWorks: () => ({id: `how-it-works`}),
    howItWorksForDoctors: () => ({id: `how-it-works-for-doctors`}),
    sirdsVeseliba: () => `/sirds-veseliba`,
    sirdsVeselibasTests: () => ({id: "cardiac-health-test"}),
    foreignPatients: () => ({id: `for-foreign-patients`}),
    internetAptieka: () => `/internet-aptieka`,
    speciality: (specialityId) => ({id: `doctor-specialities.${specialityId}`}),
    storyblokSpeciality: (specialityId) => ({
        id: `storyblok.speciality`,
        params: {localized_specialityId: storyblokRoutePart(specialityId)},
    }),
    storyblokSpecialityMapped: (specialityId, noCache) => {
        let queryParams = {}
        if (noCache) {
            queryParams['no-cache'] = 1
        }

        return pathUrl({
            path: `/storyblok/speciality/${specialityId}`,
            queryParams
        })
    },

    storyblokSpecialities: () => ({id: `storyblok.specialities`}),
    storyblokLatvianLanding: (landingId, noCache) => {
        let queryParams = {}
        if (noCache) {
            queryParams['no-cache'] = 1
        }

        return pathUrl({
            path: `/sl/${landingId}`,
            queryParams
        })
    },
    storyblokLandingMapped: (landingId) => `/storyblok/landing/${landingId}`,
    storyblokLanding: (landingId) => ({
        id: `storyblok.landing`, params: {localized_landingId: storyblokRoutePart(landingId)}
    }),
    storyblokHealthIssue: (healthIssueId) => ({
        id: `storyblok.health-issue`, params: {localized_healthIssueId: storyblokRoutePart(healthIssueId)}
    }),
    storyblokSpecificHealthIssue: (slug, noCache) => {
        let queryParams = {}
        if (noCache) {
            queryParams['no-cache'] = 1
        }

        return pathUrl({
            path: `/veselibas-problemas/${slug.substring(1)}`,
            queryParams
        })
    },
    storyblokHealthIssueMapped: (healthIssueId, noCache) => {
        let queryParams = {}
        if (noCache) {
            queryParams['no-cache'] = 1
        }

        return pathUrl({
            path: `/storyblok/health-issue/${healthIssueId}`,
            queryParams: queryParams,
        })
    },
    storyblokHealthIssues: () => ({id: `storyblok.health-issues`}),
    storyblokBlog: () => `/blog`,
    storyblokBlogPost: (blogPostId) => `/blog/${blogPostId}`,

    login: (view, error, email) => {
        let queryParams = {}
        if (error) {
            queryParams['error'] = error
        }
        if (email) {
            queryParams['email'] = email
        }

        return pathUrl({
            path: '/login',
            queryParams: queryParams,
            anchor: view
        })
    },

    registration: {
        doctor: () => ({id: `doctor-registration`}),
        patient: () => ({id: `patient-registration`})
    },
    notFound: () => "/404", // TODO moved to CommonLinks
}

let PatientLinks = {
    dashboard: () => '/my/dashboard',
    profile: {
        info: () => '/my/profile/info',
        surveys: () => '/my/profile/surveys'
    },
    reservation: {
        reservation: (doctorId, appointmentId) => `/doctors/${doctorId}/appointments/${appointmentId}`,
        payment: (doctorId, bookingId) => `/doctors/${doctorId}/bookings/${bookingId}/payment`,
        paymentStatus: (doctorId, bookingId) => `/doctors/${doctorId}/bookings/${bookingId}/payment/status`,
    },
    authentication: {
        status: (authenticationId) => `/my/authentication/${authenticationId}/status`
    },
    document: {
        view: (documentId) => backendUrl(`/api/patient/document/${documentId}`)
    },
    booking: {
        list: () => '/my/bookings',
        summary: (bookingId) => `/my/bookings/${bookingId}`,
        patientInfo: (bookingId) => `/my/bookings/${bookingId}/patient-info`,
        authentication: (bookingId) => `/my/bookings/${bookingId}/authentication`,
        anesthesiology: {
            patientConditionForm: (bookingId) => `/my/bookings/${bookingId}/anesthesiology/condition-form`,
            patientConsentForm: (bookingId) => `/my/bookings/${bookingId}/anesthesiology/consent-form`
        }
    },
    appointment: {
        patientDocument: (appointmentId, documentId) => backendUrl(`/api/patient/booking/${appointmentId}/document/${documentId}`)
    },
    appointmentRequest: {
        form: (doctorId) => `/doctors/${doctorId}/request-appointment`,
        confirm: (doctorId) => `/doctors/${doctorId}/request-appointment/confirm`,
        summary: (appointmentRequestId) => `/my/appointment-requests/${appointmentRequestId}`,
    },
    doctor: {
        doctor: (doctorId) => ({id: `doctor`, params: {doctorId}}),
        requestAppointment: (doctorId) => `/doctors/${doctorId}/request-appointment`,
    },
    sievisuSirdsVeseliba: {
        landing: () => '/sieviesu-sirds-veseliba',
        application: () => '/sieviesu-sirds-veseliba/apply',
        success: () => '/sieviesu-sirds-veseliba/success',
        alreadyExists: () => '/sieviesu-sirds-veseliba/already-exists',
    },
    viegliBut: {
        landing: () => '/viegli-but',
        application: () => '/sirds-veselibas-tests/apply',
        success: () => '/sirds-veselibas-tests/success',
        alreadyExists: () => '/sirds-veselibas-tests/already-exists',
    },
    viegliButPetijums: {
        landing: () => '/viegli-but-petijums',
        application: () => '/viegli-but-petijums/apply',
        success: () => '/viegli-but-petijums/success',
        failure: () => '/viegli-but-petijums/failure',
        alreadyExists: () => '/viegli-but-petijums/already-exists',
    },
    sirdsMazspeja: {
        landing: () => '/sirds-mazspeja',
        application: () => '/sirds-mazspeja/apply',
        success: () => '/sirds-mazspeja/success',
        alreadyExists: () => '/sirds-mazspeja/already-exists',
    },
    applyForParSirdiPromotion: () => '/sirds-veseliba/apply',
    parSirdiApplicationSuccess: () => '/sirds-veseliba/apply/success',
    parSirdiApplicationAlreadyExists: () => '/sirds-veseliba/already-exists',
    survey: {
        list: () => '/my/surveys', sirdsveseliba: () => '/my/surveys/sirds-veseliba',
    }
}

export let DoctorLinks = {
    dashboard: () => '/doctor', patient: {
        summary: (patientId) => `/doctor/patient/${patientId}`
    },
    booking: {
        list: () => `/doctor/appointments`,
        summary: (bookingId) => `/doctor/booking/${bookingId}`,
        document: (appointmentId, documentId) => backendUrl(`/api/doctor/booking/${appointmentId}/document/${documentId}`),
        downloadDocument: (appointmentId, documentId) => backendUrl(`/api/doctor/booking/${appointmentId}/document/${documentId}/download`),
        anesthesiologistForm: (bookingId) => `/doctor/booking/${bookingId}/anesthesiologist-form`,
        call: (bookingId) => `/doctor/call/${bookingId}`
    },
    appointmentRequest: {
        summary: (appointmentRequestId) => `/doctor/appointment-request/${appointmentRequestId}`,
    },
    document: {
        view: (documentId) => backendUrl(`/api/doctor/document/${documentId}/contents`)
    }
}

export let StoryblokLinks = {
    myFitness: () => '/sl/myfitness-vadosais-fitnesa-klubu-tikls-latvija'
}

export let backofficeLink = window.CONFIG.BACKEND_BASE_URL + '/api/backoffice/dashboard-1'

export default PatientLinks;
