import React from "react";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import {LoadingButton} from "@commons/ui/component/Loading";
import {EMPTY_FUNCTION} from "@commons/infra/helpers";
import {useNavigate} from "react-router-dom";
import {useLocalizedRoutes} from "@commons/infra/i18n/routes";

export let SwitchCheckbox = ({onChange, value}) => {
    return <div className="form-check form-switch">
        <input type="checkbox"
               className="form-check-input"
               id="enable-appointment-requests"
               checked={value}
               style={{cursor: "pointer"}}
               onChange={e => onChange(e.target.checked)}/>
    </div>
}

export let Radio = ({label, name, onChange, value, currentValue, disabled = false}) => {
    let internalOnChange = () => {
        if (disabled) {
            return
        }
        onChange(value)
    }

    return <div className={`form-check form-check-inline}`} onClick={internalOnChange} style={{cursor: "pointer"}}>
        <label className="form-check-label" style={{cursor: 'pointer'}}>
            <input className="form-check-input"
                   type="radio"
                   name={name}
                   checked={value === currentValue}
                   onChange={e => {
                       e.preventDefault()
                       if (disabled) return
                       onChange(value)
                   }}
                   disabled={disabled}
                   style={{cursor: "pointer"}}
            />
            {label}
        </label>
    </div>
}

export let FormButtonsContainer = ({children, breakpoint = 'md'}) => {
    return <>
        <div className={`d-flex w-100 d-${breakpoint}-none flex-column mt-3 text-center text-${breakpoint}-start`}>
            {children}
        </div>

        <div className={`d-none d-${breakpoint}-block mt-4 text-center text-${breakpoint}-start`}>
            {children}
        </div>
    </>
}

export let FormBackButton = ({to = '/#', onClick = EMPTY_FUNCTION, children, className = ''}) => {
    return <LocalizedLink to={to} onClick={onClick} className={`btn btn-link btn-lg px-4 me-3 ${className}`}>
        {children}
    </LocalizedLink>
}

export let FormProceedButton = ({
                                    className,
                                    aiIcon = 'arrow-right',
                                    onClick,
                                    to,
                                    children,
                                    isLoading,
                                    disabled = false
                                }) => {
    let navigate = useNavigate()
    let {unpack} = useLocalizedRoutes()

    return <LoadingButton isLoading={isLoading}
                          onClick={to ? () => navigate(unpack(to)) : onClick}
                          type={"submit"}
                          isDisabled={disabled}
                          className={`btn btn-lg btn-primary flex-grow-1 px-5 ${className}`}>
        {children}
        <i className={`ai-${aiIcon} ms-2`}/>
    </LoadingButton>
}