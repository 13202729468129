import axios from "@commons/infra/http/axios/axios";
import {jsonWithFilesRequest} from "@commons/infra/http/axios/request";

export const BANKLINK__TARGET_BANK__SWEDBANK = 'SWEDBANK';
export const BANKLINK__TARGET_BANK__SEB = 'SEB';
export const BANKLINK__TARGET_BANK__CITADELE = 'CITADELE';

export const PatientAppointmentsApi = {
    getBookings: ({onlyFuture = false, order = 'ASC', status = []}) => {
        let url = new URL("/api/patient/bookings", axios.defaults.baseURL);
        url.searchParams.append('onlyFuture', onlyFuture);
        url.searchParams.append('order', order);
        status.forEach(s => url.searchParams.append('status', s));

        return axios.get(url.href)
    },

    getBooking: (id) => {
        return axios.get(`/api/patient/booking/${id}`)
    },

    getCall: (bookingId) => {
        return axios.post(`/api/patient/booking/${bookingId}/call`)
    },

    reportCallJoin: (bookingId) => {
        return axios.post(`/api/frontend/patient/booking/${bookingId}/call/report-join`)
    },

    getDailyCall: (bookingId) => {
        return axios.get(`/api/patient/booking/${bookingId}/call/daily`)
    },

    reserveAppointment: (id, timeZone, promoCode) => axios.post(`/api/patient/appointment/${id}/book`, {
        timeZone,
        promoCode
    }),

    cancelAppointmentReservation: (id) => axios.post(`/api/patient/booking/${id}/cancel-reservation`),

    initiateSwedbankPayment: (id, targetBank) => axios.post(`/api/patient/booking/${id}/payment/swedbank`, {targetBank}),

    initiateStripeCheckoutSession: (id) => axios.post(`/api/patient/booking/${id}/payment/stripe`),

    getPaymentStatus: (bookingId, paymentMethod, paymentId) => axios.get(`/api/patient/booking/${bookingId}/payment/${paymentMethod}/${paymentId}`),

    updatePatientCondition: (id, payload, files = []) => axios.put(
        `/api/patient/booking/${id}/patient-condition`,
        jsonWithFilesRequest(payload, files),
        {headers: {'content-type': 'multipart/form-data'}}
    ),

    cancelBooking: (id) => {
        return axios.post(`/api/patient/booking/${id}/cancel`)
    },
};
